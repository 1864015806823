var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"coupon-list"},_vm._l((_vm.couponList),function(items,index){return _c('div',{key:index,staticClass:"mb10"},[_c('div',{staticClass:"row coupon-item",class:{
                'coupon-item-gray': _vm.isUse || _vm.isExpired,
                'coupon-list-item': !_vm.isUse && !_vm.isExpired,
            }},[_c('div',{staticClass:"price white column-center"},[_c('div',{staticClass:"row xl"},[_c('div',{staticStyle:{"margin-top":"7px"}},[_vm._v("￥")]),_c('price-slice',{attrs:{"price":items.money,"firstClass":"couponSize"}})],1),_c('div',{staticClass:"sm",staticStyle:{"margin-top":"4px"}},[_vm._v(" "+_vm._s(items.use_condition)+" ")])]),_c('div',{staticClass:"info"},[_c('div',{staticClass:"bold lg row",staticStyle:{"margin-bottom":"9px"}},[_vm._v(" "+_vm._s(items.name)+" ")]),_c('div',{staticClass:"sm lighter row"},[_vm._v(_vm._s(items.use_time_tips))]),_c('div',{staticClass:"row-between mt10"},[_c('div',{staticClass:"xs lighter two-txt-cut"},[_vm._v(" "+_vm._s(items.coupon_type)+" ")]),_c('div',{staticClass:"row-center br60",class:{
                            'white bg-primary primary-btn':
                                _vm.isCouponCenter && !_vm.isUse && !_vm.isExpired,
                            'bd-btn primary':
                                !_vm.isCouponCenter && !_vm.isUse && !_vm.isExpired,
                            'gray-btn gray': _vm.isUse || _vm.isExpired,
                        },on:{"click":function($event){return _vm.getOrUseCoupon(items.id)}}},[_vm._v(" "+_vm._s(_vm.isCouponCenter ? "领取" : _vm.isUse ? "已使用" : _vm.isExpired ? "已过期" : "去使用")+" ")])])]),_c('img',{directives:[{name:"show",rawName:"v-show",value:(items.is_get == 1),expression:"items.is_get == 1"}],staticClass:"receive",attrs:{"src":require("@A/images/home_img_receive.png")}})]),(!_vm.isCouponCenter && items.tips.length > 0)?_c('div',{staticClass:"tips-container bg-white"},[_c('div',{staticClass:"row-between header",on:{"click":function($event){return _vm.onShowTips(index)}}},[_c('div',{staticClass:"xs normal"},[_vm._v("使用说明")]),_c('div',{ref:'arrow' + index,refInFor:true,staticClass:"arrow"},[_c('img',{attrs:{"src":require("@A/images/arrow_up.png")}})])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTips[index] == 1),expression:"showTips[index] == 1"}],staticClass:"tips xs mt5 muted"},[_vm._v(" "+_vm._s(items.tips)+" ")])]):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }